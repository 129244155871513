<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebarCreate"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="enableSidebarCreate"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Nuevo Plan</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form class="p-2" @submit.prevent="createPlan">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-class="text-left">
              <b-form-input
                id="name"
                type="text"
                v-model="plan.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Precio" label-class="text-left">
              <b-input-group prepend="$" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="price"
                  type="number"
                  v-model="plan.price"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox v-model="isFreeTrial"
                >¿Activar Free Trial?</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              v-if="isFreeTrial"
              label="Free Trial"
              label-class="text-left"
            >
              <b-input-group append="días">
                <b-form-input
                  id="freeTrial"
                  type="number"
                  v-model="plan.freeTrialDays"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Ofertas" label-class="text-left">
              <v-select
                multiple
                v-model="plan.offers"
                label="name"
                dir="ltr"
                :options="offers"
                :reduce="(offer) => offer.id"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Opciones a incluir en el plan"
              label-class="text-left"
            >
              <v-select
                multiple
                v-model="plan.options"
                label="name"
                dir="ltr"
                :options="options"
                :reduce="(option) => option.id"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="submit" variant="primary">Guardar</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import Container from "../FormContainer";
import useCreatePlan from "./useCreatePlan";
import vSelect from "vue-select";
import useHandlerSidebar from "../useHandlerSidebar";
import {
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BSidebar,
} from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BSidebar,
    Container,
    vSelect,
  },
  setup() {
    const { plan, isFreeTrial, offers, options, createPlan } = useCreatePlan();
    const { enableSidebarCreate } = useHandlerSidebar();
    return { plan, isFreeTrial, offers, options, createPlan, enableSidebarCreate };
  },
};
</script>
