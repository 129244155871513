import { ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import actionName from "@actionName";
import useToastNotification from "@notification";
import { StatusNotification } from "@/constants";
import usePlan from "./usePlan";

export default function useCreatePlan() {
  const { currentApplicationId } = usePlan();
  const offers = ref([]);
  const options = ref([]);
  const plan = ref({
    userGroupTypePlanId: null,
    applicationId: currentApplicationId,
    freeTrialDays: null,
    name: "",
    price: 0,
    offers: [],
    options: [],
  });
  const isFreeTrial = ref(false);
  const {
    subscription: {
      usergrouptypeplan: { getAll: getAllUserGroupTypePlan },
      offer: { getAll: getAllOffers },
      option: { getAll: getAllOptions },
      plan: { storePlan: storePlan },
    },
  } = actionName;
  const promises = [
    async () => {
      const data = await store.dispatch(getAllUserGroupTypePlan);
      plan.value.userGroupTypePlanId = data[0].id;
    },
    async () => {
      offers.value = await store.dispatch(getAllOffers);
    },
    async () => {
      options.value = await store.dispatch(getAllOptions);
    },
  ];
  const notification = useToastNotification();

  const createPlan = async () => {
    try {
      await store.dispatch(storePlan, plan.value);
      notification.dispatchNotification(
        "Plan",
        StatusNotification.SUCCESS,
        "Plan registrado"
      );
    } catch (error) {
      notification.dispatchNotification(
        "Plan",
        StatusNotification.ERROR,
        "No se pudo registrar el plan"
      );
    }
  };

  onMounted(async () => {
    Promise.all(promises.map((f) => f()));
  });

  return {
    plan,
    isFreeTrial,
    offers,
    options,
    createPlan,
  };
}
