import { ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import actionName from "@actionName";

export default function useEditPlan() {
  const offers = ref([]);
  const options = ref([]);
  const {
    subscription: {
      offer: { getAll: getAllOffers },
      option: { getAll: getAllOptions },
    },
  } = actionName;
  const promises = [
    async () => {
      offers.value = await store.dispatch(getAllOffers);
    },
    async () => {
      options.value = await store.dispatch(getAllOptions);
    },
  ];

  onMounted(async () => {
    Promise.all(promises.map((f) => f()));
  });

  return {
    offers,
    options,
  };
}
