<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebarEdit"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="enableSidebarUpdate"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Editar Plan</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form class="p-2" @submit.prevent="editPlan">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-class="text-left">
              <b-form-input
                id="name"
                type="text"
                v-model="plan.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Precio" label-class="text-left">
              <b-input-group prepend="$" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="price"
                  type="number"
                  v-model="plan.price"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox v-model="isFreeTrial"
                >¿Activar Free Trial?</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              v-if="isFreeTrial"
              label="Free Trial"
              label-class="text-left"
            >
              <b-input-group append="días">
                <b-form-input
                  id="freeTrial"
                  type="number"
                  min="0"
                  v-model="plan.freeTrialDays"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Ofertas" label-class="text-left">
              <v-select
                multiple
                v-model="plan.offers"
                label="name"
                dir="ltr"
                :options="offers"
                :reduce="(offer) => offer.id"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Opciones a incluir en el plan"
              label-class="text-left"
            >
              <v-select
                multiple
                v-model="plan.options"
                label="name"
                dir="ltr"
                :options="options"
                :reduce="(option) => option.id"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="submit" variant="primary">Guardar</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import { ref, watch } from "@vue/composition-api";
import Container from "../FormContainer";
import useEditPlan from "./useEditPlan";
import vSelect from "vue-select";
import store from "@/store";
import useToastNotification from "@notification";
import { StatusNotification } from "@/constants";
import actionName from "@actionName";
import useHandlerSidebar from "../useHandlerSidebar";
import {
  BFormCheckbox,
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BSidebar,
} from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BSidebar,
    Container,
    vSelect,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isFreeTrial = ref(false);
    const notification = useToastNotification();
    const {
      subscription: {
        plan: { updatePlan: updatePlan },
      },
    } = actionName;

    watch(
      () => props.plan,
      (planUpdated) => {
        if (Number.isInteger(planUpdated.id) && planUpdated.id > 0) {
          isFreeTrial.value =
            planUpdated.freeTrialDays > 0 || planUpdated.freeTrialDays === "";
        } else {
          isFreeTrial.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    const editPlan = async () => {
      const plan = {
        id: props.plan.id,
        userGroupTypePlanId: props.plan.userGroupTypePlan.id,
        applicationId: props.plan.application.id,
        freeTrialDays: props.plan.freeTrialDays,
        name: props.plan.name,
        price: props.plan.price,
        offers: props.plan.offers.map((x) => x.id),
        options: props.plan.options.map((x) => x.id),
      };
      try {
        await store.dispatch(updatePlan, plan);
        notification.dispatchNotification(
          "Plan",
          StatusNotification.SUCCESS,
          "Plan actualizado"
        );
      } catch (error) {
        notification.dispatchNotification(
          "Plan",
          StatusNotification.ERROR,
          "No se pudo actualizar el plan"
        );
      }
    };

    const { offers, options } = useEditPlan();
    const { enableSidebarUpdate } = useHandlerSidebar();
    return { isFreeTrial, offers, options, editPlan, enableSidebarUpdate };
  },
};
</script>
