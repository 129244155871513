import { ref, watch, onBeforeMount, provide } from "@vue/composition-api";
import actionName from "@actionName";
import store from "@/store";
import useHandlerSidebar from "../useHandlerSidebar";

export default function usePlan() {
  const error = ref("");
  const currentApplicationId = ref(null);
  const applications = ref([]);
  const plans = ref([]);
  const isDataLoading = ref(true);
  const blankPlan = {
    userGroupTypePlanId: 0,
    applicationId: 0,
    freeTrialDays: null,
    name: "",
    price: 0,
    offers: [],
    options: [],
  };
  const plan = ref(JSON.parse(JSON.stringify(blankPlan)));

  const columns = [
    {
      key: "name",
      label: "Nombre",
    },
    {
      key: "price",
      label: "Precio",
    },
    {
      key: "freeTrialDays",
      label: "Free Trial",
    },
    {
      key: "isActive",
      label: "Estado",
    },
    {
      key: "actions",
      label: "Acciones",
    },
  ];
  const { enableSidebarUpdate } = useHandlerSidebar();
  const handleFormClick = (planData) => {
    enableSidebarUpdate(true);
    plan.value = planData;
  };

  const {
    subscription: {
      application: { getAll: getAllApplication },
      plan: {
        getPlansByApplication: getPlansByApplication,
      },
    },
  } = actionName;

  const getSuperEasyPrimeApplication = async () => {
    try {
      const data = await store.dispatch(getAllApplication);
      data.forEach((e) => {
        applications.value.push({
          text: e.name,
          value: e.id,
        });
      });
      currentApplicationId.value = applications.value[0].value;
    } catch (err) {
      error.value = err.message;
    }
  };
  const _getPlansByApplication = async (applicationId) => {
    try {
      plans.value = await store.dispatch(getPlansByApplication, applicationId);
    } catch (err) {
      error.value = err.message;
    } finally {
      isDataLoading.value = false;
    }
  }

  const onSearch = (query) => {
    console.log("Buscando -> " + query);
  };
  provide("onSearch", onSearch);

  onBeforeMount(async () => {
    await getSuperEasyPrimeApplication();
  });

  watch(currentApplicationId, async (val) => {
    if (Number.isInteger(val) && val > 0) {
      _getPlansByApplication(val);
    }
  });

  return {
    plan,
    getSuperEasyPrimeApplication,
    columns,
    currentApplicationId,
    applications,
    plans,
    isDataLoading,
    error,
    handleFormClick,
  };
}
