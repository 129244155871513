<template>
  <b-container>
    <b-form-group label="Aplicación">
      <b-form-select v-model="currentApplicationId" :options="applications" />
    </b-form-group>
    <container
      :error="error"
      title="Mantenimiento de planes"
      titleCreateButton="Nuevo Plan"
    >
      <template #body>
        <b-table
          :fields="columns"
          :items="plans"
          :busy="isDataLoading"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(actions)="row">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="20"
              @click="row.toggleDetails"
            />
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="20"
              @click="handleFormClick(row.item)"
            />
          </template>
          <template #row-details="row">
            <b-card-group>
              <b-card title="Opciones">
                <b-card-text>
                  <ul>
                    <li
                      v-for="(value, key) in row.item.options"
                      :key="key"
                    >
                      Nombre: {{ value.name }}
                    </li>
                  </ul>
                </b-card-text>
              </b-card>
              <b-card title="Ofertas">
                <b-card-text>
                  <ul>
                    <li
                      v-for="(value, key) in row.item.offers"
                      :key="key"
                    >
                      Nombre: {{ value.name }}<br />
                      Descripcion: {{ value.description }}<br />
                      Descuento: {{ value.discount_amount }}<br />
                      Duracion: {{ value.discount_amount }}<br />
                    </li>
                  </ul>
                </b-card-text>
              </b-card>
            </b-card-group>
          </template>
        </b-table>
      </template>
      <template #sidebar>
        <create-plan />
        <edit-plan :plan="plan" />
      </template>
    </container>
  </b-container>
</template>
<script>
import Container from "../Container.vue";
import usePlan from "./usePlan.js";
import CreatePlan from "./Create.vue";
import EditPlan from "./Edit.vue";
import {
  BCard,
  BCardText,
  BCardGroup,
  BCardBody,
  BContainer,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BTable,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BCardGroup,
    BCardBody,
    BContainer,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BTable,
    Container,
    CreatePlan,
    EditPlan,
  },
  setup() {
    const {
      plan,
      columns,
      currentApplicationId,
      applications,
      plans,
      isDataLoading,
      error,
      handleFormClick,
    } = usePlan();

    return {
      plan,
      columns,
      currentApplicationId,
      applications,
      plans,
      isDataLoading,
      error,
      handleFormClick,
    };
  },
};
</script>